import * as React from 'react';

export interface IFullWidthButtonProps {
  text: string;
}

export default function FullWidthButton({ text }: IFullWidthButtonProps) {
  return (
    <button className="flex-auto p-cust bg-gradient-to-r from-my-blue to-my-violet rounded-9px hover:rounded-12px w-full mt-30px hover:p-big group appearance-none focus:outline-none active:outline-none">
      <div className="bg-my-grey h-full rounded-8px flex divide-x pt-28px pb-28px group-hover:pt-25px group-hover:pb-25px justify-center text-more text-my-dividers">
        {text}
      </div>
    </button>
  );
}
