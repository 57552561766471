import Layout from '@/components/Layout';
import React from 'react';
import { fontsMaps } from '../components/fontsMaps';
import { transliterate as tr } from 'transliteration';
import Copy from '../assets/images/copy.svg';
import Share from '../assets/images/share.svg';
import Clear from '../assets/images/clear.svg';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import FullWidthButton from '@/components/FullWidthButton';
import { Link } from 'gatsby';
import { FontGrid } from '@/components/fontGrid';
import _ from 'lodash';
import { useFontsDescr } from '@/hooks/useFontDescr';
import { Helmet } from 'react-helmet';

const randomProperty = (obj: any) => {
  const keys = Object.keys(obj);
  const randomKey = keys[(keys.length * Math.random()) << 0];
  return [randomKey, obj[randomKey]];
};

const FontPage = ({ location, pageContext }) => {
  const [text, setText] = React.useState(location?.state?.text || 'Primer');
  const [result, setResult] = React.useState('');
  const [leftFocused, setLeftFocused] = React.useState(false);
  const [rightFocused, setRightFocused] = React.useState(false);

  const { nodes } = useFontsDescr();

  const otherKeys: any = React.useMemo(() => {
    return Object.fromEntries([
      randomProperty(fontsMaps),
      randomProperty(fontsMaps),
      randomProperty(fontsMaps),
      randomProperty(fontsMaps),
    ]);
  }, []);

  const [list, setList] = React.useState(
    Object.entries(otherKeys).map((s) => {
      const font = _.find(nodes, ['id', String(s[0])]);
      return {
        id: font.id,
        name: font.name,
        text: s[1](tr(text)),
      };
    }),
  );

  const fontMap = fontsMaps[pageContext.font.id];

  React.useEffect(() => {
    setResult(fontMap(tr(text)));
  }, [text]);

  React.useEffect(() => {
    setList(
      Object.entries(otherKeys).map((s) => {
        const font = _.find(nodes, ['id', String(s[0])]);
        return {
          id: font.id,
          name: font.name,
          text: s[1](tr(text)),
        };
      }),
    );
  }, [text]);

  return (
    <Layout
      header={`Шрифт ${pageContext.font.name}`}
      descr={pageContext.font.description}
      showFooter={false}
    >
      <Helmet>
        {/* <meta charSet="utf-8" /> */}
        <title>{`Шрифт ${pageContext.font.name} - Онлайн Генератор (Скопируй и Вставь!) | PrettyFont.net`}</title>
        <link rel="canonical" href={location.href} />
        <meta
          property="og:title"
          content={`Шрифт ${pageContext.font.name} - Онлайн Генератор (Скопируй и Вставь!) | PrettyFont.net`}
        />
        <meta
          name="description"
          content={`🤩 Сгенерируй шрифт ${pageContext.font.name} онлайн для Instagram, Twitter, Vkontakte, Facebook и любых других соцсетей. Выдели свой ник среди других!`}
        />
        <meta
          property="og:description"
          content={`🤩 Сгенерируй шрифт ${pageContext.font.name} онлайн для Instagram, Twitter, Vkontakte, Facebook и любых других соцсетей. Выдели свой ник среди других!`}
        />
        <meta property="og:url" content={location.href} />
        {/* <meta property="og:image:width" content="279" /> */}
        {/* <meta property="og:image:height" content="279" /> */}
        {/* <meta
          property="og:image"
          content="https://textgenerator.ru/assets/favicons/og-image.jpg"
        /> */}
      </Helmet>
      <div className="grid gap-20px md:grid-cols-2">
        <div
          className={`flex flex-col relative rounded-12px p-big ${
            leftFocused ? 'bg-gradient-to-r from-my-blue to-my-violet' : ''
          }`}
        >
          <textarea
            onFocus={() => {
              setLeftFocused(true);
            }}
            onBlur={() => {
              setLeftFocused(false);
            }}
            value={text}
            onChange={(e) => {
              setText(e.target.value);
            }}
            className="bg-white w-full appearance-none focus:outline-none active:outline-none resize-none h-180px md:h-415px px-30px py-2 text-big rounded-t-8px focus:shadow-my pt-54px"
          ></textarea>
          <div className="bg-white w-full appearance-none focus:outline-none active:outline-none rounded-b-8px pt-30px pb-30px flex justify-center border-t">
            <div
              className="cursor-pointer flex-row h-full inline-flex w-full space-x-3 items-center justify-center group"
              onClick={() => {
                setText('');
              }}
            >
              <Clear className="group-hover:filter-violet" />
              <span className="text-base text-my-blue group-hover:text-my-violet">
                Очистить
              </span>
            </div>
          </div>
          <div className="font-label absolute mb-0 leading-tighter text-gray-400 text-base cursor-text">
            {'Напишите свой текст'}
          </div>
        </div>
        <div
          className={`flex flex-col relative rounded-12px p-big ${
            rightFocused ? 'bg-gradient-to-r from-my-blue to-my-violet' : ''
          }`}
        >
          <textarea
            onFocus={() => {
              setRightFocused(true);
            }}
            onBlur={() => {
              setRightFocused(false);
            }}
            readOnly
            value={result}
            className="bg-white w-full appearance-none focus:outline-none active:outline-none resize-none h-180px md:h-415px px-30px py-2 text-big rounded-t-8px focus:shadow-my pt-54px"
          ></textarea>
          <div className="bg-white w-full appearance-none focus:outline-none active:outline-none rounded-b-8px pt-30px pb-30px flex justify-center border-t">
            <CopyToClipboard
              text={result}
              onCopy={() =>
                toast(`${result} скопирован`, {
                  // eslint-disable-next-line @typescript-eslint/quotes
                  closeButton: false,
                })
              }
            >
              <div className="cursor-pointer flex-row h-full inline-flex w-full space-x-3 items-center justify-center group">
                <Copy className="group-hover:filter-violet" />
                <span className="text-base text-my-blue group-hover:text-my-violet">
                  Копировать
                </span>
              </div>
            </CopyToClipboard>
          </div>

          <div className="font-label absolute mb-0 leading-tighter text-gray-400 text-base cursor-text">
            {'А вот и результат'}
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center mt-100px">
        <h2 className="text-h2 font-bold mb-37px text-my-black">
          Ваш текст другим шрифтом
        </h2>
        <div className="w-full">
          <FontGrid list={list} original={text} />
        </div>
      </div>
      <Link to="/">
        <FullWidthButton text="Посмотреть все" />
      </Link>
    </Layout>
  );
};
export default FontPage;
